import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import React from "react";

type LogoPropsType = {
  logoImg?: string;
  logoText?: string;
  logoLink?: string;
  textColor?: string;
  withShadow?: boolean;
  isTextHidden?: boolean;
};
export const Logo = (props: LogoPropsType) => {
  const bgShadow: React.CSSProperties = {
    filter: "drop-shadow(0 0 30px rgba(59, 81, 54, 0.5))",
  };

  return (
    <Stack
      to={props.logoLink || "/"}
      target={"_blank"}
      rel={"nofollow"}
      component={NavLink}
      alignItems={"center"}
      direction={"row"}
      gap={1.5}
    >
      <Box height={{ xs: 40, md: 60 }}>
        <img
          height={"100%"}
          src={props.logoImg}
          alt={""}
          style={props.withShadow ? bgShadow : {}}
        />
      </Box>
      <Typography
        maxWidth={90}
        fontFamily={'"Museo Sans Cyrl", sans-serif'}
        fontSize={{ xs: 10, sm: 12 }}
        color={props.textColor}
        lineHeight={"120%"}
        textTransform={"uppercase"}
        sx={{ transition: "0.2s", opacity: props.isTextHidden ? 0 : 1 }}
      >
        {props.logoText}
      </Typography>
    </Stack>
  );
};
