import { IS_DEV, StrapiDataType } from "@/common";
import { getStrapiUrl } from "@/common/utils/getStrapiUrl";
import { HeaderType } from "@/layout/types";
import { AxiosResponse } from "axios";
import { api } from "./core";

export const headerApi = {
  async getHeader() {
    try {
      const res = await api.get<AxiosResponse<StrapiDataType<HeaderType>>>(
        getStrapiUrl("/header", { populate: "logoDark, logoLight, sections" }),
      );
      if (IS_DEV) console.log("header", res);
      return res.data.data?.attributes;
    } catch (err) {
      console.log(err);
    }
  },
};
