import { alpha, createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#40805E",
    },
    secondary: {
      main: "#B1A665",
    },
    info: {
      main: "#F5F5F5",
    },
    warning: {
      main: "#dc5555",
    },

    white: {
      "024": alpha("#FFFFFF", 0.24),
      "032": alpha("#FFFFFF", 0.32),
      "064": alpha("#FFFFFF", 0.64),
      "072": alpha("#FFFFFF", 0.72),
      "084": alpha("#FFFFFF", 0.84),
      "100": "#FFFFFF",
    },
    black: {
      "004": alpha("#000000", 0.04),
      "008": alpha("#000000", 0.08),
      "016": alpha("#000000", 0.16),
      "024": alpha("#000000", 0.24),
      "032": alpha("#000000", 0.32),
      "064": alpha("#000000", 0.64),
      "100": "#000000",
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 16,
  },
  shape: {
    borderRadius: 40,
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          borderRadius: 5,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          boxShadow: "none",
          overflow: "hidden",
          maxWidth: "100%",
          padding: "8px 20px",
          [theme.breakpoints.up("md")]: {
            padding: "12px 30px",
          },

          "&:hover": {
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: "rgba(0, 0 , 0, 0.1)",
            color: "rgba(0, 0, 0, 0.6)",
          },
        }),
        containedSizeLarge: ({ theme }) =>
          theme.unstable_sx({
            p: { xs: "12px 25px", md: "15px 30px" },
            fontSize: { xs: "0.9rem", md: "1rem" },
            borderRadius: 2,
          }),
      },
    },
  },
});

theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
};

declare module "@mui/material/styles" {
  interface PaletteColor {
    "004"?: string;
    "008"?: string;
    "016"?: string;
    "024"?: string;
    "032"?: string;
    "040"?: string;
    "048"?: string;
    "064"?: string;
    "072"?: string;
    "084"?: string;
    "100"?: string;
  }

  type SimplePaletteColorOptions = PaletteColor;

  interface Palette {
    black: Palette["primary"];
    white: Palette["primary"];
  }

  interface PaletteOptions {
    white?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
  }
}
