import { AppFade } from "@/components/AppFade";
import { LinearShadow } from "@/components/LinearShadow";
import Box from "@mui/material/Box";
import { DefaultHeader } from "../default-header";
import { HeaderPropsType } from "../index";

export const HomepageHeader = (props: HeaderPropsType) => {
  // const { isHeaderHidden } = useAppController();

  return (
    <Box
      position={"absolute"}
      zIndex={10}
      width={"100%"}
    >
      <AppFade isVisible={true}>
        <LinearShadow
          side={"top"}
          size={150}
          isGlobal
        />
      </AppFade>
      <DefaultHeader
        isHidden={false}
        {...props}
      />
    </Box>
  );
};
