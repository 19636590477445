import { CloseIcon } from "@/assets/icons/CloseIcon";
import { getStrapiMediaUrl } from "@/common";
import { MonkeyInfoResponse } from "@/common/types/monkeys";
import { AppTypography } from "@/components/AppTypography";
import { Image } from "@/components/Image";
import { LinearShadow } from "@/components/LinearShadow";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface Props {
  monkeyInfo?: MonkeyInfoResponse;
  onClose: () => void;
}

export const MonkeyModal = (props: Props) => {
  const style = {
    flexDirection: { xs: "column", md: "row" },
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1,
    height: 1,
    maxWidth: 1460,
    maxHeight: { xs: "100vh", md: "70vh" },
    boxShadow: 24,
    overflow: { xs: "auto", md: "hidden" },
    borderRadius: 0.5,
    position: "relative",
    bgcolor: "white.100",
    p: { xs: 2, md: 1 },
  };

  return (
    <Modal
      open={Boolean(props.monkeyInfo)}
      onClose={props.onClose}
    >
      <Stack sx={style}>
        <AppTypography
          fontSize={"1.8rem"}
          mb={2}
          sx={{ display: { md: "none" } }}
        >
          {props.monkeyInfo?.attributes.name}
        </AppTypography>
        <Box
          minWidth={{ xs: "100%", md: "60%" }}
          width={{ xs: "100%", md: "60%" }}
          height={{ xs: 200, sm: 400, md: 1 }}
          minHeight={{ xs: 200, sm: 400, md: 1 }}
          borderRadius={0.5}
        >
          <Image
            image={props.monkeyInfo?.attributes.image}
            src={getStrapiMediaUrl(props.monkeyInfo?.attributes.image)}
          />
        </Box>
        <Stack
          className={"stylized-scrollbar"}
          sx={{
            "::-webkit-scrollbar-thumb": {
              bgcolor: "white.100",
            },
            "&:hover": {
              "::-webkit-scrollbar-thumb": {
                bgcolor: "black.016",
              },
            },
          }}
          pt={2.6}
          px={{ md: 4 }}
          overflow={{ md: "auto" }}
          position={"relative"}
          maxHeight={"100%"}
        >
          <Stack>
            <AppTypography
              variant={"h4"}
              mb={2}
              sx={{ display: { xs: "none", md: "initial" } }}
            >
              {props.monkeyInfo?.attributes.name}
            </AppTypography>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              borderRadius={1}
              bgcolor={"black.004"}
              color={"black.032"}
              px={1.5}
              py={0.5}
              mb={2}
              minHeight={20}
            >
              {/*<AppTypography>Количество</AppTypography>*/}
              {/*<AppTypography>{props.monkeyInfo?.attributes?.count}</AppTypography>*/}
            </Stack>
            <AppTypography pb={{ xs: 4, md: 0 }}>{props.monkeyInfo?.attributes.content}</AppTypography>
          </Stack>
          <LinearShadow
            side={"bottom"}
            size={40}
            sx={{ position: "sticky", display: { xs: "none", md: "flex" } }}
            rgb={"rgba(255, 255, 255, 1)"}
          />
        </Stack>
        <Box
          sx={{ opacity: 0.4, cursor: "pointer" }}
          onClick={props.onClose}
          position={"absolute"}
          right={20}
          top={20}
        >
          <CloseIcon />
        </Box>
      </Stack>
    </Modal>
  );
};
