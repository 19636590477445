import { getStrapiMediaUrl } from "@/common";
import { MonkeyInfoResponse } from "@/common/types/monkeys";
import { AppTypography } from "@/components/AppTypography";
import { Display } from "@/components/Display";
import { Image } from "@/components/Image";
import { ShadowBox } from "@/components/ShadowBox";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

interface Props {
  monkey: MonkeyInfoResponse;
}

export const MonkeyCard = (props: Props) => {
  return (
    <Grid
      item
      lg={3}
      md={4}
      sm={6}
      xs={12}
    >
      <Box
        mb={1}
        height={174}
        borderRadius={0.5}
        position={"relative"}
        overflow={"hidden"}
      >
        <Image src={getStrapiMediaUrl(props.monkey.attributes.image)} />
        <Display condition={Boolean(props.monkey.attributes.content)}>
          <ShadowBox>
            <Link to={`monkey/${props.monkey.id}`}>
              <Button variant={"contained"}>Подробнее</Button>
            </Link>
          </ShadowBox>
        </Display>
      </Box>
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        px={0.6}
      >
        <AppTypography>{props.monkey.attributes.name}</AppTypography>
        {/*<AppTypography color={"black.032"}>{props.monkey.attributes?.count}</AppTypography>*/}
      </Stack>
    </Grid>
  );
};
