import React, { useCallback } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import { ExpandTopIcon } from "@/assets/icons/ExpandTopIcon";
import { HeaderPropsType } from "@/layout";
import { NavLink } from "react-router-dom";
import { AppTypography } from "@/components/AppTypography";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack";

type MobileHeaderDrawerPropsType = HeaderPropsType & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};
export const MobileHeaderDrawer = React.memo((props: MobileHeaderDrawerPropsType) => {
  const onClose = useCallback(() => {
    props.setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    props.setIsOpen(true);
  }, []);

  return (
    <SwipeableDrawer
      anchor={"top"}
      allowSwipeInChildren
      open={props.isOpen}
      onOpen={onOpen}
      onClose={onClose}
      // sx={{
      //     pointerEvents: props.isOpen ? "initial" : "none",
      // }}
      // componentsProps={{backdrop: {invisible: !props.isOpen, open: props.isOpen}}}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        py={4}
        gap={3}
      >
        {props.sections?.map((el) => (
          <Box
            key={el.id}
            sx={{
              ...(el.attributes.slug === props.currentPage && {
                color: "primary.main",
                pointerEvents: "none",
              }),
            }}
          >
            <NavLink to={el.attributes.slug}>
              <AppTypography
                p={1.5}
                fontSize={"1rem"}
              >
                {el.attributes.text}
              </AppTypography>
            </NavLink>
          </Box>
        ))}
        <Box onClick={onClose}>
          <IconButton>
            <ExpandTopIcon />
          </IconButton>
        </Box>
      </Stack>
    </SwipeableDrawer>
  );
});
