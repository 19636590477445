import { Outlet } from "react-router-dom";
import { Header } from "./components";
import { AppLoader } from "@/components/AppLoader";
import React, { Suspense } from "react";

export const Layout = () => {
  return (
    <div>
      <Header />
      <Suspense fallback={<AppLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
};
