import { IS_DEV } from "@/common";
import { getStrapiUrl } from "@/common/utils/getStrapiUrl";
import { ContactInfoType, ContactType } from "@/modules/contacts";
import { AxiosResponse } from "axios";
import { api } from "./core";

export const contactsApi = {
  async getContacts() {
    try {
      const res = await api.get<AxiosResponse<ContactType[]>>(getStrapiUrl("/contacts", { populate: "*" }));
      if (IS_DEV) console.log("contacts", res.data.data);
      return res.data.data.sort((a, b) => a.attributes.order - b.attributes.order);
    } catch (err) {
      console.log(err);
    }
  },
  async getContactsInfo() {
    try {
      const res = await api.get<AxiosResponse<ContactInfoType[]>>(
        getStrapiUrl("/contacts-infos", {
          populate: "card, card.button, file, card.button.icon, card.button.image",
        }),
      );
      if (IS_DEV) console.log("contacts-info", res.data.data);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
};
