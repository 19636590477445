import { MonkeyInfoResponse } from "@/common/types/monkeys";
import { getStrapiUrl } from "@/common/utils/getStrapiUrl";
import { MonkeyDataType } from "@/modules/monkeys";
import { AxiosResponse } from "axios";
import { api } from "./core";

export const monkeysApi = {
  async getMonkeyImages() {
    try {
      const res = await api.get<AxiosResponse<MonkeyDataType[]>>(getStrapiUrl("/monkeys", { populate: "*" }));
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async getMonkeyInfos() {
    try {
      const res = await api.get<AxiosResponse<MonkeyInfoResponse[]>>(getStrapiUrl("/monkey-infos", { populate: "*" }));

      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
};
