import { api } from "@/app/api/core";
import { getStrapiUrl, IS_DEV } from "@/common";
import { HomePageDataType } from "@/common/types/home";
import { LibraryPageDataType } from "@/common/types/library";
import { LapinPageType } from "@/modules/lapin/types";
import { AxiosResponse } from "axios";

export const pagesApi = {
  async getHomePageData() {
    try {
      const res = await api.get<AxiosResponse<HomePageDataType>>(
        getStrapiUrl("home-page", { populate: "monkeys, monkeys.image, image" }),
      );
      if (IS_DEV) console.log("home page", res);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async getLibraryPageData() {
    try {
      const res = await api.get<AxiosResponse<LibraryPageDataType>>(getStrapiUrl("library-page", { populate: "*" }));
      if (IS_DEV) console.log("library page", res);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async getLapinPageData() {
    try {
      const res = await api.get<AxiosResponse<LapinPageType>>(
        getStrapiUrl("lapin-page", { populate: "tabs.icon, mainImage" }),
      );
      if (IS_DEV) console.log("lapin page", res);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
};
