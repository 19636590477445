import { selectIsHeaderHidden, setIsHeaderHidden } from "@/app/slice";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/common";

export const useAppController = () => {
  const dispatch = useAppDispatch();
  const isHeaderHidden = useAppSelector(selectIsHeaderHidden);

  const onHeaderHide = useCallback(() => {
    dispatch(setIsHeaderHidden(true));
  }, [dispatch]);

  const onHeaderShow = useCallback(() => {
    dispatch(setIsHeaderHidden(false));
  }, [dispatch]);

  return {
    isHeaderHidden,
    onHeaderHide,
    onHeaderShow,
  };
};
