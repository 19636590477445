export const router = {
  home: "/",
  catalog: "/catalog",
  contacts: "/contacts",
  library: "/library",
  lapin: "/lapin",
  map: "/map",
  excursions: "/vidy-ekskursij",
} as const;

export const pageNames = {
  "/": "",
  "/catalog": "Каталог",
  "/contacts": "Информация",
  "/library": "Библиотека",
  "/lapin": "Лапин",
} as const;
