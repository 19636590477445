import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import React, { useMemo } from "react";

type LinearShadowPropsType = {
  side: "left" | "right" | "top" | "bottom";
  size: React.CSSProperties["width"];
  isGlobal?: boolean;
  sx?: SxProps;
  rgb?: string;
};
export const LinearShadow = ({ rgb = "rgba(0, 0, 0, 0.5)", ...props }: LinearShadowPropsType) => {
  const isLeftOrRight = useMemo(() => {
    return props.side === "left" || props.side === "right";
  }, [props.side]);

  const gradientDirection = useMemo(() => {
    switch (props.side) {
      case "bottom":
        return "top";
      case "top":
        return "bottom";
      case "left":
        return "right";
      default:
        return "left";
    }
  }, [props.side]);

  return (
    <Box
      sx={{
        top: isLeftOrRight || props.side === "top" ? 0 : "unset",
        left: isLeftOrRight ? "unset" : "0",
        bottom: isLeftOrRight || props.side === "bottom" ? 0 : "unset",
        right: props.side === "bottom" ? 0 : "unset",
        position: props.isGlobal ? "fixed" : "absolute",
        background: `linear-gradient(to ${gradientDirection}, ${rgb}, transparent)`,
        height: isLeftOrRight ? "100%" : props.size,
        minHeight: isLeftOrRight ? "100%" : props.size,
        width: isLeftOrRight ? props.size : "100%",
        pointerEvents: "none",
        ...props.sx,
      }}
    ></Box>
  );
};
